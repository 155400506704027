<template>
    <div>
         <headerTab></headerTab>
        <div class="ab_content">
            <div class="offine_title1">请简要说明异常情况:</div>
            <textarea v-model="message_error">

            </textarea>
            <div style="imgs">
                <uploadImg :isNumber="isNumber" :uploadData="uploadData" @uploadImg="uploadImg"></uploadImg>
            </div>
            
        </div>

        <div class="infoBtn" @click="submit">提交</div>
    </div>
</template>
<script>
import { gathering_api} from "@/api/deal";
import headerTab from "../../components/headerTab.vue";
import * as apiCommon from "@/api/common";
import uploadImg from "../../components/uploadImg";
import { Toast } from "vant";
export default {
  data() {
    return {
      showPicker: false,
      imgWidth: 645,
      fileList: [],
      signtureShow: false,
      img: "",
      imgConfig: "{ useCdnDomain: true }",
      uploadData: {
        getToken: "",
        imgConfig: "{ useCdnDomain: true }",
        num:10
       },
      isNumber:true,
      iconShow: false,
      imgToken: "",
      queryList:{},
      message_error:'',
      flay:true
    };
  },
  components: {
    headerTab,
    uploadImg
  },
  created() {
    document.title = "异常反馈";
    this.getToken();
    this.queryList = this.$route.query;
  },
  methods: {
    uploadImg(img){
      this.fileList = img
    },
    submit(){
      if(this.fileList.length==0){
        Toast({
            message: "请上传凭证",
            icon: "none",
            duration: 1000
          });
          return false
      }
       if(!this.message_error){
        Toast({
            message: "请填写异常情况",
            icon: "none",
            duration: 1000
          });
          return false
      }
      if(this.flay){
        this.flay = false
      }
      let data = {
        receipt_state:2,
        receipt_info:{
          voucher:this.fileList,
          message_error:this.message_error
        }
      };
      gathering_api(data,this.queryList.id).then(res=>{
        this.flay = true
        if(res.code==0){
          Toast({
            message: "操作成功",
            icon: "success",
            duration: 1000
          });
          this.$router.go(-1)
        }
      })
      
    },
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
      this.uploadData.imgToken = res.data;
      console.log(this.imgToken, "1223131");
    },
    goClone() {
      this.$router.go(-1);
    },
    mineMessage() {},
    // 上传凭证
    beforeRead(e) {
      console.log(e, "file");
      let img = e;
      this.upload(img);
    },
    async upload(img) {
      let fd = new FormData();
      fd.append("token", this.imgToken);
      fd.append("config", this.imgConfig);
      fd.append("file", img);
      let imgRes = await apiCommon.uploadImg(fd);
      this.imgUrl = "https://cdn.health.healthplatform.xyz/" + imgRes.key;
      this.fileList.push({ url: this.imgUrl });
      console.log(this.fileList, this.imgUrl, "23213");
    },
    oversize() {},
    imgBtnClone(index, id) {
      console.log(index, "11");
      this.fileList.splice(id, 1);
    }
  }
};
</script>
<style>
.header_contract {
  display: flex;
  justify-content: space-between;
  margin-left: 40px
  /* margin-top: 10px; */
}
.header_contract div {
  
}
.imgs{
  width: 162px;
  height: 162px;
  margin: 0 auto 0;
  border:1px dashed #fff;
  position: relative;
}
.infoBtn {
  width: 388px;
  height: 90px;
  background: #deb77a;
  border-radius: 50px;
  text-align: center;
  line-height: 90px;
  font-size: 35px;
  color: #fff;
  margin: 100px auto 30px;
}
.header_contract img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.contranct_text1 {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000;
  margin-top: 20px;
}
.messageTitle3 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle3 {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 20px;
  color: #333;
}
.userTitle {
  color: #0f87ff;
  font-size: 24px;
  letter-spacing: 2.5px;
  line-height: 32px;
}
.ab_content {
  width: 620px;
  margin: 100px auto 60px;
  font-size: 35px;
  color:#FF3E46
}
.offine_title1 {
  font-size: 35px;
  margin-top: 30px;
  font-weight: 400;
  margin-bottom: 30px
}
.formList {
  margin-top: 30px;
  font-size: 26px;
  font-weight: 400;
}
.van-uploader__upload {
  width: 162px;
  height: 162px;
  background: #f1f8ff;
  border: 1px solid #63b0ff;
  opacity: 1;
  border-radius: 5px;
}
.imgClass {
  position: relative;
  display: flex;
}
.imgClass img {
  width: 100px;
  height: 100px;
}
.box_clone {
  width: 20px;
  /* height:20px; */
  border-radius: 20px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}
.box_clone .imgs {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.ab_content textarea{
    width:620px;height: 300px;
    margin: 0 auto
}
</style>
